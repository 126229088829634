import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import shallow from "zustand/shallow";
import Heading from "../../shared/Heading";
import Page from "../../shared/Page";
import { Card, Col, Grid, Row } from "../../shared/Layout";
import useRouting from "../../../utils/hooks/useRouting";
import NavigationButtons from "../../shared/NavigationButtons";
import usePageTitle from "../../../utils/hooks/usePageTitle";
import mediaQuery from "../../../utils/mediaQuery";
import usePrefillHandler from "../../../utils/hooks/usePrefillHandler";
import LoadingSpinnerPage from "../../shared/LoadingSpinnerPage";
import useStore from "../../../store/store";
import { setCustomAttributeForNewRelicData } from "../../../utils/newRelic";
import { useButtonId } from "../../../utils/hooks/usePageScopedId";
import useApplicationChannel from "../../../utils/hooks/useApplicationChannel";
import { SBB_WEB } from "../../../constants/applicationChannelTypes";
import { isFeatureEnabled } from "../../../utils/configSelector";

const CardContainer = styled(Row).attrs({
  className: "grv-margin__top--large-3",
})`
  @media not (${mediaQuery.medium}) {
    /* On small screens, put a gap between the tiles */
    gap: var(--grv-size-spacing-medium-1);
    /* Also reduce the top margin */
    margin-block-start: var(--grv-size-spacing-medium-2);
  }
`;

const NavButtonsWithMargin = styled(NavigationButtons).attrs({
  className: "grv-margin__top--medium-2",
  nextProps: { type: "button" },
})`
  @media (${mediaQuery.medium}) {
    margin-block-start: var(--grv-size-spacing-large-3);
  }
`;

const CustomerTypeCard = styled(Card).attrs({
  role: "button",
  tabIndex: "0",
  className: "grv-card--color-bar",
})`
  /* Make the card appear as a button */
  cursor: pointer;
  /* Change the color of the top border */
  border-color: var(--grv-color-interaction-blue-60);

  .grv-card__content {
    /* Remove the internal margin and let the card contents add it back */
    margin-block-start: 0;
    /* Reduce the internal padding as well, so the content flows wider in the card */
    padding-inline: var(--grv-size-spacing-medium-1);
  }

  /* Selected styling is based on the ARIA attribute to ensure consistency */
  &[aria-pressed="true"] {
    background: var(--grv-color-interaction-blue-10);
    /* 
      To avoid overwriting the outline that tab focus will add, the blue border needs
      to be added as an actual border. However, we don't want to cause content shifts,
      so the padding and margin of the card content is adjusted by the size of the border.

      Also, the width of the top border needs to remain what it already was from Gravity, 8px.
    */
    --selected-border-width: 3px;
    border: var(--selected-border-width) solid var(--grv-color-interaction-blue-50);
    border-top-width: var(--grv-size-spacing-small-2);
    .grv-card__content {
      padding-inline: calc(var(--grv-size-spacing-medium-1) - var(--selected-border-width));
      margin-bottom: calc(var(--grv-size-spacing-medium-2) - var(--selected-border-width));
    }
  }
`;

const CustomerTypeCardButton = ({ value, selectedButton, onSelect, children }) => (
  <CustomerTypeCard
    id={useButtonId(`${value.toLowerCase()}Customer`)}
    aria-pressed={selectedButton === value}
    onClick={useCallback(() => onSelect(value), [onSelect, value])}
    onKeyPress={useCallback(({ key }) => key === "Enter" && onSelect(value), [onSelect, value])}
  >
    {children}
  </CustomerTypeCard>
);

const EXISTING = "EXISTING";
const NEW = "NEW";

const CustomerTypeCheck = () => {
  usePageTitle("Do we know you?");
  // Error state, used when trying to move on without choosing an option
  const [error, setError] = useState(false);

  // If the hasApplicantSelectedCustomerType flag is set in the store, then we need to show
  // the new customer option as previously selected.
  // Because post-prefill attempt personal info and verify prefill both lack a way to return to this page,
  // there is no way to previously select existing and return here.
  const [hasApplicantSelectedCustomerType, setCustomerTypeSelected, encourageExpandedCustomerSignInEnabled] =
    useStore(
      state => [
        state.hasApplicantSelectedCustomerType,
        state.setCustomerTypeSelected,
        isFeatureEnabled(state, "encourageExpandedCustomerSignIn"),
      ],
      shallow
    );
  const applicationChannel = useApplicationChannel();
  const encourageSignOnEnabled = encourageExpandedCustomerSignInEnabled && applicationChannel === SBB_WEB;
  const [selected, setSelected] = useState(hasApplicantSelectedCustomerType ? NEW : null);
  // The onSelect function will update the state to the provided target, unless
  // that target is already selected, in which case the state will be falsy.
  // Also clears the error state regardless.
  // Only works when not currently submitting, to prevent trying to swap choices
  const onSelect = useCallback(target => {
    setSelected(old => old === target || target);
    setError(false);
  }, []);

  const navigate = useNavigate(); // never on review page, no need to case on that
  const [backRoute, nextRoute] = useRouting();
  const { handlePrefill } = usePrefillHandler();

  // No error handling wrapper necessary in submission since there is no application update associated with this, just rerouting.
  // If the pre-fill request itself errors in an unhandled way, it already re-routes to an error page.
  const onSubmit = useCallback(async () => {
    setCustomAttributeForNewRelicData("customerTypeSelected", selected);
    switch (selected) {
      case EXISTING:
        navigate("/loading");
        await handlePrefill(); // navigates away in every case
        return;
      case NEW:
        setCustomerTypeSelected(); // only relevant in this case, since there is no way to return to this page after selecting EXISTING
        navigate(nextRoute);
        return;
      default:
        setError(true);
    }
  }, [selected, nextRoute, handlePrefill, navigate, setCustomerTypeSelected]);

  if (!handlePrefill) {
    return <LoadingSpinnerPage />;
  }

  return (
    <Page>
      <Grid>
        <Heading
          step="SECTION 1 OF 4"
          mainHeading={
            encourageSignOnEnabled
              ? "Do you have a Capital One account?"
              : "Do you have a small business bank account with us?"
          }
          subHeading={
            encourageSignOnEnabled ? (
              <span>
                Sign in if you have any Capital One accounts such as a business deposit account, a credit
                card, or a personal account. We’ll use your account to confirm some information as part of the
                application process.
              </span>
            ) : (
              <span>
                You <span className="grv-weight--semibold">must sign in</span> if you have at least 1 active
                small business deposit account at Capital One (e.g., Business Basic Checking) or you’ll need
                to reapply.
              </span>
            )
          }
        />
        <CardContainer>
          <Col lg={4} md={4} sm={4} offset={{ lg: 2 }}>
            <CustomerTypeCardButton value={EXISTING} selectedButton={selected} onSelect={onSelect}>
              <div className="grv-weight--semibold grv-margin__top--medium-1">
                Yes, I have an account <em>or</em> I&apos;m unsure
              </div>
              {encourageSignOnEnabled ? (
                <div className="grv-margin__top--medium-1">
                  This includes, but is not limited to, personal or business deposit accounts (e.g., 360
                  Checking, Business Savings, CDs), loans, and credit cards (e.g., Savor, Venture,
                  Quicksilver, Spark Cash Plus).
                </div>
              ) : (
                <div className="grv-margin__top--medium-1">
                  After you sign in to your account, we may be able to fill in part of the application for
                  you. If you&apos;re unsure, we can check if you have an account.
                </div>
              )}
            </CustomerTypeCardButton>
          </Col>
          <Col lg={4} md={4} sm={4}>
            <CustomerTypeCardButton value={NEW} selectedButton={selected} onSelect={onSelect}>
              <div className="grv-weight--semibold grv-margin__top--medium-1">
                No, I don&apos;t have an account
              </div>
              {encourageSignOnEnabled ? (
                <div className="grv-margin__top--medium-1">
                  If you don’t have a Capital One account, you can still start the application.
                </div>
              ) : (
                <div className="grv-margin__top--medium-1">
                  If you don&apos;t have an active small business bank account, you can still start the
                  application.
                </div>
              )}
            </CustomerTypeCardButton>
          </Col>
        </CardContainer>
        {error && (
          <Row className="grv-margin__top--small-2">
            <Col lg={8} md={8} sm={4} offset={{ lg: 2 }}>
              <span role="alert" className="grv-text grv-color--interaction-red-50">
                You must select a response
              </span>
            </Col>
          </Row>
        )}
      </Grid>
      <NavButtonsWithMargin backRoute={backRoute} onNext={onSubmit} />
    </Page>
  );
};

export default CustomerTypeCheck;
